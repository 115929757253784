import * as React from "react";
import { useCookies } from "react-cookie";
import { cookieUrl, privacyUrl } from "../utils/UrlStrings";

export const CookieContainer = () => {
  const cookieName = "cak-accepted-cookies";

  const [cookies, setCookie] = useCookies([cookieName]);

  const handleCookie = () => {
    const exdays = 365;
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);

    setCookie(cookieName, 1, {
      path: "/",
      expires: exdate,
      secure: true,
      sameSite: "strict",
    });
  };

  return (
    <>
      {!cookies[cookieName] && (
        <div className="container cookies cookies__container">
          <div className="cookies__content">
            <h2 className="cookies__title">
              De website van het CAK maakt gebruik van cookies.
            </h2>
            <br />
            Wij kunnen u dan zo goed mogelijk helpen. Het accepteren van{" "}
            <a
              className="cookies__link"
              href={cookieUrl}
              target="_blank"
              rel="noreferrer"
              aria-label="cookies (opent in een nieuwe tab)"
            >
              cookies
            </a>{" "}
            zorgt ervoor dat deze website goed werkt en dat wij onze website
            steeds kunnen verbeteren.
            <br />
            Uiteraard zorgen wij goed voor uw{" "}
            <a
              className="cookies__link"
              href={privacyUrl}
              target="_blank"
              rel="noreferrer"
              aria-label="privacy (opent in een nieuwe tab)"
            >
              privacy
            </a>
            <br />
          </div>

          <div>
            <button
              type="button"
              onClick={handleCookie}
              className="cookies__button"
            >
              Melding niet meer tonen
            </button>
          </div>
        </div>
      )}
    </>
  );
};
