import * as React from "react";
import { Link } from "react-router-dom";
import env from "../env";
import { cookieUrl, privacyUrl } from "../utils/UrlStrings";

const opentInEenNieuwTab = "(opent in een nieuwe tab)";
const schermDelenString = "Scherm delen";

export const FooterContainer = () => {
  const cobrowsingRenderRef = React.useRef(false);

  React.useEffect(() => {
    if (!cobrowsingRenderRef.current) {
      cobrowsingRenderRef.current = true;
      try {
        const e = document.createElement("script");
        e.type = "text/javascript";
        e.async = !0;
        e.id = "cb_script";
        e.crossOrigin = "anonymous";
        e.src = "https://app.conversation24.com/sdk/loader.js";
        const t = document.getElementsByTagName("script")[0];
        if (t && t.parentNode) {
          t.parentNode.insertBefore(e, t);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("FooterContainer cobrowsing error", e);
      }
    }
  }, []);

  return (
    <nav
      className="navbar home-colofon-container"
      role="navigation"
      aria-label="Footer navigation"
    >
      <div className="container container-no-padding">
        <ul className="navbar-nav mr-auto" id="footer-nav-list">
          <li className="nav-item active">
            <a
              className="nav-link"
              href={cookieUrl}
              target="_blank"
              rel="noreferrer"
              aria-label={`cookies ${opentInEenNieuwTab}`}
            >
              cookies
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://www.hetcak.nl/proclaimer"
              target="_blank"
              rel="noreferrer"
              aria-label={`proclaimer ${opentInEenNieuwTab}`}
            >
              proclaimer
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href={privacyUrl}
              target="_blank"
              rel="noreferrer"
              aria-label={`uw privacy ${opentInEenNieuwTab}`}
            >
              uw privacy
            </a>
          </li>
          <li className="nav-item" data-id="CB57f28149569b0cf53d">
            <div className="cb_button cb_online">
              <span className="nav-link cb_chatbutton">
                {schermDelenString}
              </span>
            </div>
            <div className="cb_button cb_accepted">
              <span className="nav-link cb_chatbutton cb_chatbutton--active">
                {schermDelenString}
              </span>
            </div>
            <div className="cb_button cb_offline" />
          </li>
        </ul>
        <Link
          className="navbar-brand"
          to="/"
          tabIndex={0}
          id="footer-navbar-brand"
        >
          <img
            src={`${env.APPLICATION_PATH}images/cak-minilogo.png`}
            alt="Logo C.A.K"
          />
        </Link>
      </div>
    </nav>
  );
};
